<template>
    <a-card :bordered="false">

    </a-card>
</template>

<script>

    import { triggerWindowResizeEvent } from '@/utils/my-utils';
    import { updateTheme, updateColorWeak, colorList } from '@/components/tools/setting'

    export default {
        name: "Analysis",
        created() {
            this.changeUI('#1890FF', 'light', 'sidemenu', 'Fixed', false, false, false, false, false);
        },
        methods: {

            // --------------------------------------------------------------------------------------------------------- ui start
            /**
             *  修改UI
             * @param primaryColor                  默认主题色 #1890FF
             * @param navTheme                      两种主题:['dark', 'light']
             * @param layout                        两种整体布局方式: ['sidemenu', 'topmenu']
             * @param contentWidth                  内容区布局： 流式 |  固定
             * @param fixSiderbar                   固定左侧菜单栏 ： boolean
             * @param fixedHeader                   固定 Header : boolean
             * @param fixedHeaderHide               向下滚动时，隐藏 Header : boolean
             * @param colorWeak                     色盲模式
             * @param multipage                     默认多页签模式
             */
            changeUI: function (primaryColor, navTheme, layout, contentWidth, fixSiderbar, fixedHeader, fixedHeaderHide, colorWeak, multipage) {
                // color
                //this.$store.dispatch('ToggleColor', primaryColor);
                //updateTheme(primaryColor);
                // 主题
                this.$store.dispatch('ToggleTheme', navTheme);
                // 布局
                this.changeLayout(layout);
                // 内容区布局
                this.$store.dispatch('ToggleContentWidth', contentWidth);
                // 固定 Header
                this.$store.dispatch('ToggleFixedHeader', fixedHeader);
                // 向下滚动时，隐藏 Header
                this.$store.dispatch('ToggleFixedHeaderHidden', fixedHeaderHide)
                // 色盲
                //this.$store.dispatch('ToggleWeak', colorWeak);
                //updateColorWeak(colorWeak);
                // 多页
                this.$store.dispatch('ToggleMultipage', multipage);
            },
            changeLayout: function(mode){
                this.$store.dispatch('ToggleLayoutMode', mode);
                // 因为顶部菜单不能固定左侧菜单栏，所以强制关闭
                this.handleFixSiderbar(false);
                // 触发窗口resize事件
                triggerWindowResizeEvent();
            },
            handleFixSiderbar (fixed) {
                if (this.layoutMode === 'topmenu') {
                    fixed = false
                }
                this.$store.dispatch('ToggleFixSiderbar', fixed)
            },
            //---------------------------------------------------------------------------------------------------------- ui end
            test: function () {
                // 默认 light + sidemenu
                this.changeUI('#1890FF', 'light', 'sidemenu', 'Fixed', false, false, false, false, false);
                // dark + top布局
                //this.changeUI('#1890FF', 'dark', 'topmenu', 'Fixed', false, false, false, false, false);
            }
        },
    }
</script>

<style scoped>

</style>
